<template>
  <div id="demo">
    <div class="content_box">
      <div class="inner">
        <div class="row top">
          <div class="title_box">
            <img src="@/assets/images/dialog_true.png" alt />
            <p>{{ $t('register.demo.congrad') }}</p>
          </div>
          <div class="main_box">
            <span class="title">{{ $t('register.demo.title', { platform: userInfo.platform }) }}</span>
            <br />
            <span>{{ $t('register.demo.title2') }}</span>
            <span>{{ $t('register.demo.li.login') }} {{ userInfo.accNum }}</span>
            <span v-if="userInfo.Password">{{ $t('register.demo.li.password') }} {{ userInfo.Password }}</span>
            <span>{{ $t('register.demo.li.srv') }} {{ userInfo.server }}</span>
            <p>{{ $t('register.demo.emailSent') }}</p>

            <router-link :to="{ name: 'register' }" class="el-button purple_button">
              {{ $t('register.demo.openLiveAcc') }}
            </router-link>
          </div>
        </div>
        <div class="row bottom">
          <strong>{{ $t('register.demo.dl', { platform: userInfo.platform }) }}</strong>
          <p>{{ $t(`downloads.mt${userInfo.platform}Desc`) }}</p>
          <ul v-if="urls[`mt${userInfo.platform}`]">
            <li>
              <a :href="urls[`mt${userInfo.platform}`].Windows">
                <div class="icon">
                  <img src="@/assets/images/downloads/icons-big-windows@3x.png" alt="" />
                </div>
                <span>{{ $t('downloads.windows') }}</span>
                <img src="@/assets/images/downloads/icons-navigation-arrow-right@3x.png" alt="" />
              </a>
            </li>
            <li>
              <a :href="urls[`mt${userInfo.platform}`].MacOS">
                <div class="icon"><img src="@/assets/images/downloads/icons-big-apple@3x.png" alt="" /></div>
                <span>{{ $t('downloads.mac') }}</span>
                <img src="@/assets/images/downloads/icons-navigation-arrow-right@3x.png" alt="" />
              </a>
            </li>
            <li>
              <a :href="urls[`mt${userInfo.platform}`].Android">
                <div class="icon">
                  <img src="@/assets/images/downloads/icons-big-android@3x.png" alt="" />
                </div>
                <span>{{ $t('downloads.android') }}</span>
                <img src="@/assets/images/downloads/icons-navigation-arrow-right@3x.png" alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet_demo_accs } from '@/resource';

export default {
  data() {
    return {
      urls: this.$config.getMetaTraderLinks(this.$store.state.common.regulator),
      userInfo: {}
    };
  },
  mounted() {
    apiGet_demo_accs().then(resp => {
      if (resp.data.code == 0) {
        this.userInfo = { ...resp.data.data[0] };
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/demo.scss';
</style>
